import type {
  CasinoLobbyInterface,
  ProviderCasinoResApiInterface,
  ResponseApiCasinoBetTransactionInterface,
} from 'types/casino';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import type { Config } from './use-request';
import useRequest from './use-request';
import { useMediaImagePaths } from '~hooks/use-media';

export const useCasinos = (
  currency?: string,
  config?: Config<ProviderCasinoResApiInterface[]>,
): {
  data: ProviderCasinoResApiInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<ProviderCasinoResApiInterface[]>>;
} => {
  const { withImageLogoPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<ProviderCasinoResApiInterface[]>(
    {
      url: '/casinos',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );

  const getProviderWithLogoPath = (
    providers: ProviderCasinoResApiInterface[] | undefined,
  ) => {
    return (
      providers?.map((provider) => {
        const logo = withImageLogoPath(provider);
        return {
          ...provider,
          logo,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: getProviderWithLogoPath(data),
  };
};

export const useCasinoLobby = (
  currency: string,
  provider: string,
  config?: Config<CasinoLobbyInterface[]>,
): {
  data: CasinoLobbyInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<CasinoLobbyInterface[]>>;
} => {
  const { withImageThumbnailPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<CasinoLobbyInterface[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/casinos/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );

  const getProviderWithThumbnailPath = (
    providers: CasinoLobbyInterface[] | undefined,
  ) => {
    return (
      providers?.map((game) => {
        const thumbnail = withImageThumbnailPath(game);
        return {
          ...game,
          thumbnail,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: getProviderWithThumbnailPath(data),
  };
};

export const useCasinoBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiCasinoBetTransactionInterface>,
) => {
  return useRequest<ResponseApiCasinoBetTransactionInterface>(
    {
      url: '/casinos/bet-transactions',
      params: {
        startAt: query.startAt,
        endAt: query.endAt,
        provider: query.provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};

export const useCasinosInfoHelper = (currency: string) => {
  const { data } = useCasinos(currency);
  const getCasinoProviderImage = useCallback(
    (provider: string) => {
      const casinoProvider = data?.find((i) => i.id === provider);
      if (!casinoProvider) {
        return;
      }
      return {
        ...objectify(
          casinoProvider?.images,
          (f) => f.name as string,
          (f) => f.value as string,
        ),
        logo: casinoProvider.logo,
      };
    },
    [data],
  );
  return {
    getCasinoProviderImage,
  };
};
