import type {
  ProviderSportResApiInterface,
  ResponseApiSportBetTransactionInterface,
  SportsbookLobbyInterface,
} from 'types/sport';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse, Method } from 'axios';
import type { Config } from './use-request';
import useRequest from './use-request';
import { useMediaImagePaths } from '~hooks/use-media';

export const useSportsbook = (
  currency?: string,
  config?: Config<ProviderSportResApiInterface[]>,
): {
  data: ProviderSportResApiInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<ProviderSportResApiInterface[]>>;
} => {
  const { withImageLogoPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<ProviderSportResApiInterface[]>(
    {
      url: '/sportsbooks',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );

  const getProviderWithLogoPath = (
    providers: ProviderSportResApiInterface[] | undefined,
  ) => {
    return (
      providers?.map((provider) => {
        const logo = withImageLogoPath(provider);
        return {
          ...provider,
          logo,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: getProviderWithLogoPath(data),
  };
};

export const useSportsbookLobby = (
  currency: string,
  provider: string,
  config?: Config<SportsbookLobbyInterface[]>,
): {
  data: SportsbookLobbyInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<SportsbookLobbyInterface[]>>;
} => {
  const { withImageThumbnailPath } = useMediaImagePaths();

  const { data, ...rest } = useRequest<SportsbookLobbyInterface[]>(
    provider
      ? {
          method: 'GET' as Method,
          url: `/sportsbooks/${provider}/games`,
          params: {
            currency,
          },
        }
      : null,
    config,
  );

  const getProviderWithThumbnailPath = (
    providers: SportsbookLobbyInterface[] | undefined,
  ) => {
    return (
      providers?.map((game) => {
        const thumbnail = withImageThumbnailPath(game);
        return {
          ...game,
          thumbnail,
        };
      }) || []
    );
  };

  return {
    ...rest,
    data: getProviderWithThumbnailPath(data),
  };
};

export const useSportsbookInfoHelper = (currency: string) => {
  const { data } = useSportsbook(currency);
  const getSportProviderImage = useCallback(
    (provider: string) => {
      const sportProvider = data?.find((i) => i.id === provider);
      if (!sportProvider) {
        return;
      }
      return objectify(
        sportProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getSportProviderImage,
  };
};

export const useSportBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiSportBetTransactionInterface>,
) => {
  return useRequest<ResponseApiSportBetTransactionInterface>(
    {
      url: '/sportsbooks/bet-transactions',
      params: {
        startAt: query.startAt,
        endAt: query.endAt,
        provider: query.provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};
